import React, { useEffect, useRef } from "react";
import { Fade, Popover } from "@material-ui/core";
import styled from "styled-components";
import Board from "./Board";
import { Chessground } from "chessground";

const StyledDeviation = styled.div``;

const StyledPopover = styled(Popover)`
  pointer-events: none;
`;

const ModalBoardContainer = styled.div`
  margin: auto;
  width: 400px;
  justify-content: center;
  overflow: hidden;
`;

const BoardModal = ({ anchorEl, setAnchorEl, move, showChance }) => {
  const open = Boolean(anchorEl) && Boolean(move);
  const bottomCenter = {
    vertical: "bottom",
    horizontal: "center",
  };
  const topCenter = {
    vertical: "top",
    horizontal: "center",
  };
  let anchorOrigin = bottomCenter;
  let transformOrigin = topCenter;

  if (anchorEl) {
    let spaceToBottom =
      window.innerHeight - anchorEl.offsetTop + anchorEl.offsetHeight;
    if (spaceToBottom < 500) {
      anchorOrigin = topCenter;
      transformOrigin = bottomCenter;
    }
    console.log(spaceToBottom);
  }
  let cg;
  const createBoard = (element) => {
    if (element === null || move === null) {
      if (cg) {
        cg.destroy();
      }
      return;
    }
    cg = Chessground(element, { fen: move.nextFen });
  };

  return (
    <StyledPopover
      id="mouse-over-popover"
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={() => {
        cg.destroy();
        setAnchorEl(null);
      }}
      disableRestoreFocus
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 0 }}
    >
      <ModalBoardContainer>
        <Board ref={createBoard} />
      </ModalBoardContainer>
      {move &&
        move.deviations &&
        Object.entries(move.deviations).map((deviation) => (
          <StyledDeviation>
            <span>Played Move: {deviation[0]} </span>{" "}
            <span style={{ float: "right" }}>
              {" "}
              {deviation[1]} time{deviation[1] != 1 && "s"}
            </span>
          </StyledDeviation>
        ))}

      {move && showChance && (
        <div>
          <p>Master chance: {move.masterTotalChance} </p>
          <p>Lichess 2200 chance: {move.lichess2200TotalChance} </p>
          <p>Lichess 2500 chance: {move.lichess2500TotalChance} </p>
        </div>
      )}
    </StyledPopover>
  );
};
export default BoardModal;
