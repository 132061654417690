import React from "react";
import { Study } from "./Study";
import "chessground/assets/chessground.base.css";
import "chessground/assets/chessground.brown.css";
import "chessground/assets/chessground.cburnett.css";
import Board from "./Board";

class Chessboard extends React.Component {
  constructor(props) {
    super(props);
    this.boardRef = React.createRef();
  }
  startStudy() {
    const config = {
      startVariationCallback: this.props.startVariationCallback,
      currentMoveCallback: this.props.currentMoveCallback,
      commentsCallback: this.props.commentsCallback,
      setWaitForUserCallback: this.props.setWaitForUserCallback,
    };
    this.study = new Study(this.boardRef.current, config, this.props.apiUrl);
  }
  componentDidMount() {
    this.startStudy();
  }
  componentDidUpdate(prevProps) {
    if (this.props.apiUrl != prevProps.apiUrl) {
      this.startStudy();
    }
    if (
      this.props.waitForUser != prevProps.waitForUser &&
      this.props.waitForUser == false
    ) {
      this.study.nextVariation();
    }
  }

  render() {
    return <Board ref={this.boardRef}></Board>;
  }
}

export default Chessboard;
