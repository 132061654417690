import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import styled from "styled-components";

const StyledAppBar = styled(AppBar)`
    &&{
        background-color: white;
        margin-bottom 24px; 
        flex-grow: 1;
    }
`;

const Login = styled(Link)`
  && {
    margin-left: 12px;
    margin-right: 12px;
    color: #ff4d71;
  }
`;
const Title = styled(Typography)`
  && {
    color: #ff4d71;
    margin-left: 12px;
  }
`;

const Nav = ({ authed }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToNav = (path) => {
    handleClose();
    navigate(path);
  };

  return (
    <StyledAppBar position="static">
      <Toolbar>
        <IconButton onClick={handleClick}>
          <MenuIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => goToNav("/book")}>Books</MenuItem>
          <MenuItem onClick={() => goToNav("/study")}>Study</MenuItem>
          <MenuItem onClick={() => goToNav("/import")}>PGN Import</MenuItem>
          <MenuItem onClick={() => goToNav("/deviations")}>Deviations</MenuItem>
        </Menu>

        <Title variant="h6">Anne Loves Chess</Title>
        {!authed && <Login to="/login">Log In</Login>}
      </Toolbar>
    </StyledAppBar>
  );
};

export default Nav;
