import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

const DeleteDialog = ({
  selectedObject,
  setSelectedObject,
  handleDelete,
  objectType,
}) => (
  <Dialog
    onClose={() => setSelectedObject(null)}
    open={!!selectedObject}
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{" Delete Book?"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Continuing will delete {objectType}{" "}
        <strong>{selectedObject && selectedObject.name}</strong> and all of its
        moves.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={() => setSelectedObject(null)}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="secondary"
        inverted
        onClick={() => {
          handleDelete();
          setSelectedObject(null);
        }}
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteDialog;
