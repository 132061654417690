import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Button, Card, CardActions } from "@material-ui/core";

import styled from "styled-components";
import BookCardContent from "./BookCardContent";
import ChapterCardContent from "./ChapterCardContent";
import axios from "axios";
const StyledCard = styled(Card)`
  width: 300px;
  margin: 12px;
`;

const GreenButton = styled(Button)`
  background-color: green;
  color: white;
`;
const StyledAction = styled(CardActions)`
  justify-content: center;
`;
const GridCard = ({ object, setSelectedObject, objectName, refetch }) => {
  const navigate = useNavigate();
  function onStudyClick(e) {
    e.preventDefault();
    navigate(`/study/${objectName}/${object.id}`);
  }
  function onLearnClick(e) {
    e.preventDefault();
    navigate(`/study/${objectName}/${object.id}?learn=true`);
  }

  function onPauseClick(e) {
    e.preventDefault();
    let toPause = !object.paused;
    axios
      .put(`/api/${objectName}/pause`, { [objectName]: object, toPause })
      .then(refetch)
      .catch((error) => console.log(error));
  }

  return (
    <Grid item>
      <StyledCard
        style={{ backgroundColor: object.paused ? "grey" : "white" }}
        variant="outlined"
      >
        {objectName === "book" && <BookCardContent book={object} />}
        {objectName === "chapter" && <ChapterCardContent chapter={object} />}
        <StyledAction>
          <GreenButton variant="contained" onClick={onLearnClick}>
            Learn
          </GreenButton>
          <Button variant="contained" color="primary" onClick={onStudyClick}>
            Study
          </Button>
          <Button variant="contained" onClick={onPauseClick}>
            {object.paused ? "Resume" : "Pause"}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={(event) => {
              event.preventDefault();
              setSelectedObject(object);
            }}
          >
            Delete
          </Button>
        </StyledAction>
      </StyledCard>
    </Grid>
  );
};

export default GridCard;
