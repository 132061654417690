import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import chapter from "./Chapter";

const StyledMove = styled.div`
  padding: 4px;
  flex-shrink: 0;
  background: ${(props) => {
    if (props.isActiveFen) {
      return "lightgreen";
    }

    if (props.paused) {
      return "grey";
    }
    if (props.noChance) {
      return "palevioletred";
    }
    if (props.skip || props.outOfDepth) {
      return "grey";
    }
    return "white";
  }};
`;
const Move = ({
  move,
  setSelectedMove,
  outOfDepth,
  setHoveredMove,
  isActiveFen,
  minChance,
}) => (
  <StyledMove
    onMouseEnter={() => {
      setHoveredMove(move);
    }}
    onMouseLeave={() => {
      setHoveredMove(null);
    }}
    skip={move.skip}
    outOfDepth={outOfDepth}
    onClick={() => {
      setSelectedMove(move);
    }}
    paused={move.paused}
    isActiveFen={isActiveFen}
    noChance={
      move &&
      minChance &&
      !(
        move.masterTotalChance > minChance ||
        move.lichess2200TotalChance > minChance ||
        move.lichess2500TotalChance > minChance
      )
    }
  >
    {move.colour && `${move.moveNumber}.`} {move.notation}
  </StyledMove>
);

const StyledVariation = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 24px 12px 24px 12px;
  border-style: solid;
  background: ${(props) => {
    if (props.isPaused) {
      return "grey";
    }
    return "white";
  }};
`;
const PauseButton = styled(Button)`
  && {
    margin-left: auto;
  }
`;

const Variation = ({
  variation,
  chapter,
  setSelectedMove,
  pauseVariation,
  setBoardModalEl,
  setHoveredMove,
  activeFen,
}) => {
  const lastMove = variation.lastMove;

  return (
    <StyledVariation
      isPaused={lastMove.paused}
      onMouseEnter={(event) => setBoardModalEl(event.currentTarget)}
      onMouseLeave={() => setBoardModalEl(null)}
    >
      {variation.moveList.map((move) => (
        <Move
          move={move}
          outOfDepth={move.moveNumber > chapter.maxDepth}
          setSelectedMove={setSelectedMove}
          setHoveredMove={setHoveredMove}
          setBoardModalEl={setBoardModalEl}
          isActiveFen={activeFen === move.prevFen}
          minChance={chapter.chance}
        ></Move>
      ))}
      <PauseButton
        variant="contained"
        onClick={() => pauseVariation(variation, !lastMove.paused)}
      >
        {lastMove.paused ? "Resume" : "Pause"}
      </PauseButton>
    </StyledVariation>
  );
};

export default Variation;
