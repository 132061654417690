import React from "react";
import { CardContent, Typography } from "@material-ui/core";

const BookCardContent = ({ book }) => {
  return (
    <CardContent>
      <Typography color="textSecondary" gutterBottom>
        {book.name}
      </Typography>
    </CardContent>
  );
};

export default BookCardContent;
