import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";

const StyledMove = styled.div`
  padding: 4px;
  flex-shrink: 0;
  background: ${(props) => {
    if (props.hasDeviations) {
      return "red";
    }
    return "white";
  }};
`;
const DeveationMove = ({ move, setHoveredMove }) => (
  <StyledMove
    onMouseEnter={() => {
      setHoveredMove(move);
    }}
    onMouseLeave={() => {
      setHoveredMove(null);
    }}
    hasDeviations={Object.entries(move.deviations).length > 0}
  >
    {move.colour && `${move.moveNumber}.`} {move.notation}
  </StyledMove>
);

const StyledVariation = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 24px 12px 24px 12px;
  border-style: solid;
`;

const DeviationVariation = ({ variation, setBoardModalEl, setHoveredMove }) => {
  return (
    <StyledVariation
      onMouseEnter={(event) => setBoardModalEl(event.currentTarget)}
      onMouseLeave={() => setBoardModalEl(null)}
    >
      {variation.moves.map((move) => (
        <DeveationMove
          move={move}
          setHoveredMove={setHoveredMove}
          setBoardModalEl={setBoardModalEl}
        ></DeveationMove>
      ))}
    </StyledVariation>
  );
};

export default DeviationVariation;
