import React from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";

const BookForm = () => {
  return (
    <div style={{ marginTop: "50px" }}>
      <Formik
        initialValues={{ name: "", file: null, colour: true }}
        onSubmit={({ file, name, colour }, { setSubmitting }) => {
          let formData = new FormData();
          formData.append("file", file);
          formData.append("name", name);
          formData.append("colour", colour);
          axios
            .post(`/api/book`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => setSubmitting(false));
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <Field type="input" name="name" />
            <input
              id="file"
              name="file"
              type="file"
              onChange={(event) => {
                setFieldValue("file", event.currentTarget.files[0]);
              }}
            />
            <Field as="select" name="colour">
              <option value={true}>White</option>
              <option value={false}>Black</option>
            </Field>
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BookForm;
