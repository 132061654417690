import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import GridCard from "./GridCard";
import { Grid, Container } from "@material-ui/core";
import DeleteDialog from "./DeleteDialog";
import DepthControl from "./DepthControl";
import Schedule from "./Schedule";
import styled from "styled-components";
import { Field, Form, Formik } from "formik";

const DepthWapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
`;

function Book() {
  let { bookId } = useParams();
  const [book, setBook] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);

  const updateDepth = (event) => {
    let value = event.target.value;
    if (value === "") {
      return;
    }
    axios
      .put(`/api/book/depth`, { ...book, MaxDepth: value })
      .then((response) => response && response.data && setBook(response.data))
      .catch((error) => console.log(error));
  };

  const updateChance = (value) => {
    const chance = parseFloat(value);
    axios
      .put(`/api/book/chance`, { ...book, Chance: chance })
      .then((response) => response && response.data && setBook(response.data))
      .catch((error) => console.log(error));
  };

  function handleDelete() {
    axios.delete(`/api/chapter/${selectedChapter.id}`).then(fetchChapters);
  }

  const fetchChapters = () => {
    axios
      .get(`/api/book/${bookId}/chapters`)
      .then((response) => setChapters(response.data || []))
      .catch((error) => console.log(error));
  };
  const fetchBook = () => {
    axios
      .get(`/api/book/${bookId}`)
      .then((response) => setBook(response.data || []))
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    fetchChapters();
    fetchBook();
  }, []);
  return (
    <Container>
      <h1>Chapters</h1>
      <DepthWapper>
        <DepthControl maxDepth={book.maxDepth} updateDepth={updateDepth} />
        <Formik
          enableReinitialize={true}
          initialValues={{ chance: book.chance || 0 }}
          onSubmit={({ chance }) => {
            updateChance(chance);
          }}
        >
          <Form>
            <Field type="input" name="chance" />
            <button type="submit">Update Chance</button>
          </Form>
        </Formik>
      </DepthWapper>
      <Grid container justifyContent="center" spacing={2}>
        {chapters.map((chapter) => (
          <Link key={chapter.id} to={`/chapter/${chapter.id}`}>
            <GridCard
              object={chapter}
              setSelectedObject={setSelectedChapter}
              objectName="chapter"
              refetch={fetchChapters}
            />
          </Link>
        ))}
      </Grid>
      <DeleteDialog
        setSelectedObject={setSelectedChapter}
        selectedObject={selectedChapter}
        handleDelete={handleDelete}
        objectType="chapter"
      />
      <Schedule bookId={bookId} />
    </Container>
  );
}

export default Book;
