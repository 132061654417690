import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import BookForm from "./BookForm";
import Chapter from "./Chapter";
import StudyPage from "./StudyPage";
import "./App.css";
import Login from "./Login";
import Cookies from "js-cookie";
import Nav from "./Nav";
import Home from "./Home";
import classNames from "classnames";
import Books from "./Books";
import Book from "./Book";
import DeviationPage from "./DeviationPage";

const PrivateRoute = ({ authed, children }) => {
  if (!authed) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function isAuthed() {
  const authed = Cookies.get("logged_in");
  if (!authed) {
    return false;
  }
  return true;
}

export default function App() {
  const [authed, setAuthed] = useState(isAuthed());

  const location = useLocation();
  return (
    <div
      className={classNames("site-wrapper", {
        "study-backgound": location.pathname.startsWith("/study"),
      })}
    >
      <Nav authed />
      <div>
        {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/login" element={<Login setAuthed={setAuthed} />} />
          <Route
            authed={authed}
            path={`/chapter/:chapterId`}
            element={
              <PrivateRoute authed={authed}>
                <Chapter />
              </PrivateRoute>
            }
          />
          <Route
            authed={authed}
            path={`/book/:bookId`}
            element={
              <PrivateRoute authed={authed}>
                <Book />
              </PrivateRoute>
            }
          />
          <Route
            authed={authed}
            path={`/book`}
            element={
              <PrivateRoute authed={authed}>
                <Books />
              </PrivateRoute>
            }
          />
          <Route
            authed={authed}
            path={"/study/*"}
            element={
              <PrivateRoute authed={authed}>
                <StudyPage />
              </PrivateRoute>
            }
          />
          <Route
            authed={authed}
            path={"/import"}
            element={
              <PrivateRoute authed={authed}>
                <BookForm />
              </PrivateRoute>
            }
          />
          <Route
            authed={authed}
            path={"/deviations"}
            element={
              <PrivateRoute authed={authed}>
                <DeviationPage />
              </PrivateRoute>
            }
          />
          <Route path="/" component={Home} />
        </Routes>
      </div>
    </div>
  );
}
