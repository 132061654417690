import React, { useEffect, useState } from "react";
import axios from "axios";
import { Field, Form, Formik } from "formik";

function Schedule({ bookId }) {
  const [schedule, setSchedule] = useState({});
  const defaultShedule = {
    steakHours0: 12,
    steakHours1: 24,
    steakHours2: 24 * 2,
    steakHours3: 24 * 2,
    steakHours4: 24 * 4,
    steakHours5: 24 * 8,
    steakHours6: 24 * 16,
    steakHours7: 24 * 32,
    steakHours8: 24 * 64,
  };
  useEffect(() => {
    axios
      .get(`/api/book/${bookId}/schedule`)
      .then((response) => {
        setSchedule(response.data || defaultShedule);
      })
      .catch((error) => console.log(error));
  }, [bookId]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={schedule}
      onSubmit={(data, { setSubmitting }) => {
        axios
          .put(`/api/book/${bookId}/schedule`, data, {})
          .then(() => setSubmitting(false));
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div>
            <label htmlFor="steakHours0">Steak 0 Hours</label>
            <Field type="number" name="steakHours0"></Field>
          </div>
          <div>
            <label htmlFor="steakHours1">Steak 1 Hours</label>
            <Field type="number" name="steakHours1"></Field>
          </div>
          <div>
            <label htmlFor="steakHours2">Steak 2 Hours</label>
            <Field type="number" name="steakHours2"></Field>
          </div>
          <div>
            <label htmlFor="steakHours3">Steak 3 Hours</label>
            <Field type="number" name="steakHours3"></Field>
          </div>
          <div>
            <label htmlFor="steakHours4">Steak 4 Hours</label>
            <Field type="number" name="steakHours4"></Field>
          </div>
          <div>
            <label htmlFor="steakHours5">Steak 5 Hours</label>
            <Field type="number" name="steakHours5"></Field>
          </div>
          <div>
            <label htmlFor="steakHours6">Steak 6 Hours</label>
            <Field type="number" name="steakHours6"></Field>
          </div>
          <div>
            <label htmlFor="steakHours7">Steak 7 Hours</label>
            <Field type="number" name="steakHours7"></Field>
          </div>
          <div>
            <label htmlFor="steakHours8">Steak 8 Hours</label>
            <Field type="number" name="steakHours8"></Field>
          </div>
          <button type="submit" disabled={isSubmitting}>
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
}
export default Schedule;
