import React, { useEffect, useState } from "react";
import Chessboard from "./Chessboard";
import { useNavigate, useLocation } from "react-router-dom";
import qs from "qs";
import {
  Button,
  Container,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";

const ActionButton = styled.div`
  margin-left: 12px;
`;
const Variations = styled.div`
  line-height: 1.5;
  padding: 6px;
  margin-left: 12px;
`;

const Comment = styled(Typography)`
  &&& {
    background-color: white;
    margin-top: 12px;
    padding: 6px;
    border-radius: 6px;
  }
`;

export const ActionWapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-right: 20px;
  margin-bottom: 24px;
`;
const BoardContainer = styled.div`
  margin: auto;
  max-width: 600px;
  justify-content: center;
`;
const StudyPage = () => {
  // Dont accidentally scroll page when entering moves.
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => (document.body.style.overflowY = "auto");
  }, []);

  const location = useLocation();
  let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [numVariations, setNumVariations] = useState(null);
  const [currentMove, setCurrentMove] = useState(null);
  const [comments, setComments] = useState([]);
  const [movesOnly, setMovesOnly] = useState(false);
  const [waitForUser, setWaitForUser] = useState(false);
  const [learn, setLearn] = useState(queryParams.learn === "true");

  const apiParams = qs.stringify({ learn, movesOnly });

  const apiUrl = `/api${location.pathname}?${apiParams}`;
  return (
    <Container>
      <BoardContainer>
        <ActionWapper>
          <Variations>Variations to study {numVariations}</Variations>
          <ActionButton>
            {waitForUser && (
              <Button
                variant={"contained"}
                onClick={() => setWaitForUser(false)}
              >
                Continue
              </Button>
            )}
          </ActionButton>
          <ActionButton>
            <FormControlLabel
              control={
                <Switch
                  checked={movesOnly}
                  onChange={() => setMovesOnly(!movesOnly)}
                  color="primary"
                  name="movesOnly"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Moves Only"
            />
          </ActionButton>
        </ActionWapper>
        <Chessboard
          apiUrl={apiUrl}
          currentMoveCallback={setCurrentMove}
          startVariationCallback={setNumVariations}
          commentsCallback={setComments}
          setWaitForUserCallback={setWaitForUser}
          waitForUser={waitForUser}
        />
        {comments.map((comment) => (
          <Comment>{comment}</Comment>
        ))}
        <ActionWapper>
          <ActionButton>
            {currentMove && (
              <Button
                variant={"contained"}
                onClick={() =>
                  navigate(
                    `/chapter/${currentMove.chapterId}?fen=${encodeURIComponent(
                      currentMove.prevFen,
                    )}`,
                  )
                }
              >
                See in Chapter
              </Button>
            )}
          </ActionButton>
          <ActionButton>
            {currentMove && (
              <Button
                variant={"contained"}
                onClick={() =>
                  window.open(
                    `https://lichess.org/analysis?fen=${encodeURIComponent(
                      currentMove.prevFen,
                    )}`,
                  )
                }
              >
                Analysis
              </Button>
            )}
          </ActionButton>
        </ActionWapper>
      </BoardContainer>
    </Container>
  );
};

export default StudyPage;
