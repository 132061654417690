import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import GridCard from "./GridCard";
import DeleteDialog from "./DeleteDialog";
function Books() {
  const [books, setBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const fetchData = () => {
    axios
      .get(`/api/book`)
      .then((response) => setBooks(response.data || []))
      .catch((error) => console.log(error));
  };
  const handleDelete = () => {
    axios
      .delete(`/api/book/${selectedBook.id}`)
      .then(fetchData())
      .catch((error) => console.log(error));
  };

  useEffect(fetchData, []);

  return (
    <Container>
      <h1>Books</h1>
      <Grid container justifyContent="center" spacing={2}>
        {books.map((book) => (
          <Link to={`/book/${book.id}`}>
            <GridCard
              key={book.id}
              object={book}
              objectName="book"
              setSelectedObject={setSelectedBook}
              refetch={fetchData}
            />
          </Link>
        ))}
      </Grid>
      <DeleteDialog
        setSelectedObject={setSelectedBook}
        selectedObject={selectedBook}
        handleDelete={handleDelete}
        objectType="book"
      />
    </Container>
  );
}

export default Books;
