import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { Chessground } from "chessground";
import Board from "./Board";
import styled from "styled-components";

const DialogBoardContainer = styled.div`
  margin: auto;
  width: 400px;
  justify-content: center;
  overflow: hidden;
`;

const MoveActionDialog = ({
  selectedMove,
  setSelectedMove,
  handleSkip,
  handlePauseFrom,
}) => {
  let cg;
  const createBoard = (element) => {
    if (element === null || selectedMove === null) {
      if (cg) {
        cg.destroy();
      }
      return;
    }
    cg = Chessground(element, { fen: selectedMove.nextFen });
  };

  return (
    <Dialog
      onClose={() => setSelectedMove(null)}
      open={!!selectedMove}
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Move Actions"}</DialogTitle>
      <DialogContent>
        <DialogBoardContainer>
          <Board ref={createBoard} />
        </DialogBoardContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setSelectedMove(null)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          inverted
          onClick={() => {
            handleSkip(selectedMove);
            setSelectedMove(null);
          }}
        >
          Skip To Move
        </Button>
        <Button
          variant="contained"
          inverted
          onClick={() => {
            handlePauseFrom(selectedMove);
            setSelectedMove(null);
          }}
        >
          Pause From Move
        </Button>
      </DialogActions>
      {selectedMove && (
        <div>
          <p>Master chance: {selectedMove.masterTotalChance} </p>
          <p>Lichess 2200 chance: {selectedMove.lichess2200TotalChance} </p>
          <p>Lichess 2500 chance: {selectedMove.lichess2500TotalChance} </p>
        </div>
      )}
    </Dialog>
  );
};

export default MoveActionDialog;
