import React from "react";
import { CardContent, Typography } from "@material-ui/core";

const ChapterCardContent = ({ chapter }) => {
  return (
    <CardContent>
      <Typography color="textSecondary" gutterBottom>
        {chapter.name}
      </Typography>
      <Typography color="textSecondary" gutterBottom>
        Learnt {chapter.learntCount} / {chapter.variationCount}
      </Typography>
    </CardContent>
  );
};

export default ChapterCardContent;
