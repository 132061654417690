import React from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = ({ setAuthed }) => {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "50px" }}>
      LOGIN
      <Formik
        initialValues={{ username: "", password: "" }}
        onSubmit={(data, { setSubmitting }) => {
          axios.post(`/api/login`, data).then(() => {
            setSubmitting(false);
            setAuthed(true);
            navigate("/study");
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field type="input" name="username" />
            <Field type="password" name="password" />
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
