import styled from "styled-components";

const Board = styled.div`
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
`;

export default Board;
