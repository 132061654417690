import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Variation from "./Variation";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import DepthControl from "./DepthControl";
import BoardModal from "./BoardModal";
import { parseISO } from "date-fns";
import MoveActionDialog from "./MoveActionDialog";
import qs from "qs";

const ActionButton = styled(Button)`
  && {
    margin-right: 24px;
  }
`;

const BackButton = styled(Button)`
  && {
    margin-left: 24px;
  }
`;

const ChapterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
`;

const Page = styled.div`
  background: ${(props) => {
    if (props.isPaused) {
      return "grey";
    }
  }};
`;

const ChapterName = styled.div`
  text-align: center;
  font-size: 32px;
  font-family: "Segoe UI", Arial, sans-serif;
  font-weight: 400;
  margin: 10px 0;
`;
function Chapter() {
  let { chapterId } = useParams();
  const [variations, setVariations] = useState([]);
  const [selectedMove, setSelectedMove] = useState(null);
  const [chapter, setChapter] = useState({});
  const [boardModalEl, setBoardModalEl] = React.useState(null);
  const [hoveredMove, setHoveredMove] = React.useState(null);
  const navigate = useNavigate();

  const location = useLocation();
  let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [activeFen, setActiveFen] = useState(queryParams.fen);

  useEffect(() => {
    setActiveFen(queryParams.fen);
  }, [queryParams.fen]);

  const canLearnMore = variations.some(
    (variation) => parseISO(variation.lastMove.lastAttempt).getFullYear() === 1,
  );

  const backToBook = (e) => {
    e.preventDefault();
    navigate(`/book/${chapter.bookId}`);
  };

  const fetchChapter = () => {
    axios
      .get(`/api/chapter/${chapterId}`)
      .then((response) => setChapter(response.data || []))
      .catch((error) => console.log(error));
  };

  const fetchMoves = () => {
    axios
      .get(`/api/chapter/${chapterId}/moves`)
      .then((response) => setVariations(response.data || []))
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    fetchMoves();
    fetchChapter();
  }, []);

  const skipMoves = (move) => {
    axios
      .put(`/api/move/skip`, move)
      .then(() => fetchMoves())
      .catch((error) => console.log(error));
  };

  const updateDepth = (event) => {
    let value = event.target.value;
    if (value === "") {
      return;
    }
    axios
      .put(`/api/chapter/depth`, { ...chapter, MaxDepth: value })
      .then(
        (response) => response && response.data && setChapter(response.data),
      )
      .catch((error) => console.log(error));
  };

  const pauseVariation = (variation, toPause) => {
    const move = variation.lastMove;
    axios
      .put(`/api/move/pause`, { move, toPause })
      .then(() => fetchMoves())
      .catch((error) => console.log(error));
  };

  const pauseFromMove = (move) => {
    axios
      .put(`/api/move/pause_from`, move)
      .then(() => fetchMoves())
      .catch((error) => console.log(error));
  };

  const pauseChapter = () => {
    let toPause = !chapter.paused;
    axios
      .put(`/api/chapter/pause`, { chapter, toPause })
      .then(() => fetchChapter())
      .catch((error) => console.log(error));
  };
  const skipLearning = () => {
    axios
      .put(`/api/chapter/mark_learnt`, chapter)
      .then(() => fetchMoves())
      .catch((error) => console.log(error));
  };

  return (
    <Page isPaused={chapter.paused}>
      <MoveActionDialog
        selectedMove={selectedMove}
        setSelectedMove={setSelectedMove}
        handleSkip={skipMoves}
        handlePauseFrom={pauseFromMove}
      />
      <ChapterButtons>
        <div>
          <BackButton variant="contained" onClick={backToBook}>
            Back To Book
          </BackButton>
        </div>
        <div>
          {canLearnMore && (
            <ActionButton variant="contained" onClick={skipLearning}>
              Mark Learnt
            </ActionButton>
          )}
          <ActionButton variant="contained" onClick={pauseChapter}>
            {chapter.paused ? "Resume Chapter" : "Pause Chapter"}
          </ActionButton>
          <DepthControl maxDepth={chapter.maxDepth} updateDepth={updateDepth} />
        </div>
      </ChapterButtons>
      <ChapterName>{chapter.name}</ChapterName>

      {variations.map((variation) => (
        <Variation
          key={variation.lastMove.id}
          chapter={chapter}
          variation={variation}
          setBoardModalEl={setBoardModalEl}
          setHoveredMove={setHoveredMove}
          setSelectedMove={setSelectedMove}
          pauseVariation={pauseVariation}
          pauseFromMove={pauseFromMove}
          activeFen={activeFen}
        ></Variation>
      ))}
      <BoardModal
        anchorEl={boardModalEl}
        setAnchorEl={setBoardModalEl}
        move={hoveredMove}
        showChance
      />
    </Page>
  );
}

export default Chapter;
