import { Chessground } from "chessground";
import axios from "axios";
import { parseISO } from "date-fns";

export function callUserFunction(f, ...args) {
  if (f) setTimeout(() => f(...args), 1);
}

class Move {
  constructor(moveItem, next) {
    this.id = moveItem.id;
    this.colour = moveItem.colour;
    this.from = moveItem.from;
    this.to = moveItem.to;
    this.prevFen = moveItem.prevFen;
    this.nextFen = moveItem.nextFen;
    this.playersMove = moveItem.playersMove;
    this.chapterId = moveItem.chapterId;
    this.learning =
      this.playersMove && parseISO(moveItem.lastAttempt).getFullYear() === 1;
    this.next = next;
    this.previous = null;
    this.comment = moveItem.comment;
  }
}

class Variation {
  constructor(variationItem, next) {
    this.lastMove = variationItem.lastMove;
    this.next = next;
    this.previous = null;
    this.move = null;
    this.hasEnded = false;
    this.studyOver = false;
    this.atteptedMoves = [];
    if (variationItem.moveList) {
      // Pre loaded moves and single move variations
      this.move = buildLinkedList(variationItem.moveList, Move);
      this.due = true;
    }
  }
  loadMoves() {
    if (this.move === null) {
      return axios
        .get(`/api/variation/${this.lastMove.id}`)
        .then((response) => {
          const data = response.data;
          this.due = data.due;
          this.move = buildLinkedList(data.moveList, Move);
          console.log("Moves Loaded");
        })
        .catch((error) => console.log(error));
    }
    return Promise.resolve();
  }

  nextMove() {
    if (this.hasEnded) {
      console.log("Variation has ended");
      return;
    }
    this.move = this.move.next;
    if (!this.move) {
      this.endVariation();
    }
  }
  endVariation() {
    this.hasEnded = true;
    this.saveAttempts();
    console.log("end variation");
  }
  nextPlayerMove() {
    this.nextMove();
    if (!this.hasEnded) {
      /*Computer moves always updated as correct. This is needed because we need
            the last update time for the final move when
            a variation ends on a computer move */
      this.recordAttempt(true);
    }
    this.nextMove();
  }
  currentFen() {
    return this.move.prevFen;
  }
  recordAttempt(correct) {
    if (!this.atteptedMoves.some((move) => move.id === this.move.id)) {
      this.atteptedMoves.push({
        id: this.move.id,
        correct: correct,
        timestamp: new Date(),
      });
    }
  }

  saveAttempts() {
    axios
      .put(`/api/study/record`, this.atteptedMoves)
      .catch((error) => console.log(error));
  }
  currentMoveOriginDest() {
    return [this.move.from, this.move.to];
  }

  getComments() {
    let comments = [];
    const previousMove = this.hasEnded ? this.lastMove : this.move.previous;
    if (previousMove) {
      comments.push(previousMove.comment);
      if (previousMove.previous) {
        comments.push(previousMove.previous.comment);
      }
    }
    return comments.filter((comment) => comment !== "").reverse();
  }
}

const buildLinkedList = (listData, nodeClass) => {
  const copy = [...listData].reverse();
  let node = null;
  let next = null;
  for (const item of copy) {
    node = new nodeClass(item, next);
    if (next) next.previous = node; // Double link
    next = node;
  }
  return node; // Root
};

export class Study {
  constructor(el, config, url) {
    const chessGroundConfig = {
      events: {
        move: this.onMove,
      },
      movable: {
        free: false,
      },
      animation: {
        duration: 400,
      },
    };
    this.cg = Chessground(el, chessGroundConfig);
    this.url = url;
    this.getVariations();
    this.variation = undefined;
    this.studyCount = undefined;
    this.config = config;
    this.learntMoveIds = [];
    window.study = this;
  }

  lockBoard() {
    this.cg.set({ movable: { free: false } });
  }
  unLockBoard() {
    this.cg.set({ movable: { free: true } });
  }
  setOrientation() {
    const move = this.currentMove();
    this.cg.set({
      orientation: move.playersMove && move.colour ? "white" : "black",
    });
  }
  playComputerMove() {
    this.variation.nextPlayerMove();
    if (!this.variation.hasEnded) {
      this.setCurrentFen();
    }
  }

  maybeStartLearning() {
    if (
      this.currentMove() &&
      this.currentMove().learning &&
      !this.learntMoveIds.includes(this.currentMove().id)
    ) {
      this.displayLearningSequence();
    }
  }
  displayLearningSequence() {
    const animationSpeed = 500;
    let learningMove = this.currentMove();
    let i = 0;

    const scheduleMove = () => {
      this.setFen(learningMove.nextFen);
      this.learntMoveIds.push(learningMove.id);

      if (
        i < 6 &&
        learningMove.next &&
        (!learningMove.next.playersMove || learningMove.next.learning)
      ) {
        i++;
        learningMove = learningMove.next;
        setTimeout(scheduleMove, animationSpeed);
      } else {
        setTimeout(this.setCurrentFen(), animationSpeed);
      }
    };
    scheduleMove();
  }
  startVariation() {
    if (!this.currentMove().playersMove) {
      this.variation.nextMove();
    }
    if (this.variation.hasEnded || !this.variation.due) {
      this.nextVariation();
      return;
    }
    this.setOrientation();
    this.setCurrentFen();
    this.maybeStartLearning();
    callUserFunction(this.config.currentMoveCallback, this.currentMove());
    callUserFunction(this.config.commentsCallback, this.getComments());
    this.unLockBoard();
  }

  endStudy() {
    this.studyOver = true;
  }
  currentMove() {
    return this.variation.move;
  }

  getComments() {
    return this.variation.getComments();
  }

  nextVariation() {
    this.variation = this.variation.next;
    this.studyCount -= 1;
    callUserFunction(this.config.startVariationCallback, this.studyCount);

    if (!this.variation) {
      this.endStudy();
    } else {
      this.variation.loadMoves().then(() => {
        this.startVariation();
      });
    }
  }

  setCurrentFen() {
    const fen = this.variation.currentFen();
    this.setFen(fen);
  }

  setFen(fen) {
    this.cg.set({ fen: fen });
  }

  correctMove(orig, dest) {
    const [correctOrig, correctDest] = this.variation.currentMoveOriginDest();
    return orig === correctOrig && dest === correctDest;
  }

  onMove = (orig, dest) => {
    this.lockBoard();
    const correct = this.correctMove(orig, dest);
    this.variation.recordAttempt(correct);

    if (correct) {
      this.playComputerMove();
    } else {
      this.setCurrentFen();
      this.displayCorrectMove();
    }

    if (this.variation.hasEnded) {
      if (this.getComments()) {
        callUserFunction(this.config.commentsCallback, this.getComments());
        this.config.setWaitForUserCallback(true);
      } else {
        this.nextVariation();
      }
    } else {
      this.maybeStartLearning();
      callUserFunction(this.config.currentMoveCallback, this.currentMove());
      callUserFunction(this.config.commentsCallback, this.getComments());
      this.unLockBoard();
    }
  };
  displayCorrectMove() {
    const [correctOrig, correctDest] = this.variation.currentMoveOriginDest();
    this.cg.setShapes([
      { orig: correctOrig, dest: correctDest, brush: "green" },
    ]);
  }

  getVariations() {
    axios
      .get(this.url)
      .then((response) => {
        const data = response.data;
        this.studyCount = data.length;
        if (data.length) {
          this.variation = buildLinkedList(data, Variation);
          this.variation.loadMoves().then(() => {
            this.startVariation();
          });
        }
        if (!this.variation) {
          this.endStudy();
        }
        callUserFunction(this.config.startVariationCallback, this.studyCount);
      })
      .catch((error) => console.log(error));
  }
}
