import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const MaxDepth = styled(FormControl)`
  width: 100px;
  .MuiSelect-root {
    background-color: #e0e0e0;
    border-radius: 4px;
  }
`;

const DepthControl = ({ maxDepth, updateDepth }) => {
  const options = [
    <MenuItem key={0} value={""}>
      Max Depth
    </MenuItem>,
  ];
  for (let i = 1; i < 100; i++) {
    options.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }

  return (
    <MaxDepth variant="filled">
      <InputLabel>Max Depth</InputLabel>
      <Select value={maxDepth || ""} onChange={updateDepth}>
        {options}
      </Select>
    </MaxDepth>
  );
};

export default DepthControl;
