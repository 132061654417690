import React from "react";
import { Container } from "@material-ui/core";
import heartChess from "../images/heart_chess.jpg";

const Home = () => {
  return (
    <div>
      <Container text>
        <h1
          style={{
            fontSize: "4em",
            fontWeight: "normal",
            marginBottom: "1em",
            marginTop: "1em",
            textAlign: "center",
            // color: "#ff4d71"
          }}
        >
          Anne Loves Chess
        </h1>
      </Container>
      <Container>
        <img
          className="home-image"
          src={heartChess}
          alt="heart"
          style={{
            maxWidth: "100%",
            borderRadius: "10px",
          }}
        />
      </Container>
    </div>
  );
};
export default Home;
